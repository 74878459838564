import React from "react";
import image1 from "../Assets/Images/marquee1.png";
import image2 from "../Assets/Images/marquee2.png";
import image3 from "../Assets/Images/marquee3.png";
import image4 from "../Assets/Images/marquee4.png";
import image5 from "../Assets/Images/marquee5.png";
import image6 from "../Assets/Images/marquee6.png";

const ImageMarquee = () => {
  return (
    <div class="Marquee">
      <div class="Marquee-content">
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image3} alt="" />
            <img src={image5} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image3} alt="" />
            <img src={image5} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
      <div class="Marquee-content">
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image3} alt="" />
            <img src={image5} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image3} alt="" />
            <img src={image5} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image4} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </div>
        </div>
        <div class="Marquee-tag">
          <div className="Marquee-inner-image">
            <img src={image6} alt=""  />
          </div>
          <div className="Marquee-secondary-image">
            <img src={image3} alt="" />
            <img src={image5} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageMarquee;
