import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FAQ_BACKGROUND from "../Assets/Images/FAQBack.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
import Loader from "../Component/Loader";
const FAQ = () => {
  const [faqList, getFAQList] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const getFaqList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.FAQ_GET);
      if (response?.data?.s === 1) {
        getFAQList(response?.data?.r);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFaqList();
  }, []);
  return (
    <>
      <div
        id="faq"
        style={{
          width: "100%",
          background: `url(${FAQ_BACKGROUND})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "calc(100vh - 80px)",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <div className="text-container">
          <h1 className="outlined-text">REACH YOUR GOALS</h1>
        </div>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              color: "white",
              fontSize: {
                xs: "22px",
                md: "32px",
                fontWeight: "500",
                textAlign: "center",
              },
            }}
          >
            Frequently asked questions
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: "95%", md: "70%", lg: "50%" },
            height: "600px",
            overflow: "scroll",
          }}
        >
          {!loading ? (
            faqList.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  sx={{
                    my: 2,
                    py: { xs: 0.8, md: 1.2 },
                    borderRadius: "4px",
                    background:
                      "linear-gradient(93.25deg, #CBB5FF -0.03%, #EFE9FF 100.86%)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: { xs: "600", md: "500" },
                    }}
                  >
                    {item.question}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: { xs: "500", md: "400" },
                    }}
                  >
                    {item.answer}
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Loader />
          )}
        </Box>
      </div>
    </>
  );
};

export default FAQ;
