import React from "react";
import PARTNER_LOGO from "../Assets/Images/PartnerLogo.png";
const PartnerMarquee = () => {
  return (
    <>
      <div class="Partner-Marquee">
        <div class="Partner-Marquee-content">
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          
        </div>
        <div class="Partner-Marquee-content">
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          <div class="Partner-Marquee-tag">
            <div className="Partner-Marquee-inner-image">
              <img src={PARTNER_LOGO} alt="" />
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default PartnerMarquee;
