import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AxiosInstance from "../Config/AxiosInstance";
import APILIST from "../Config/ApiList";
const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const [data, setData] = useState(null);
  const gettermsandconditions = async () => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_TERMS_AND_CONDITIONS);console.log(res)
      if (res?.data?.r) {
        setData(res?.data?.r);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };

  useEffect(() => {
    gettermsandconditions();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          mt: "80px",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            background: "#0D0C14",
            p: { xs: 2, md: 10 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "800px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "30px", lg: "46px" },
                fontWeight: "600",
                textAlign: "center",
                color: "white",
              }}
            >
              Terms And Conditions
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            p: 2,
            maxWidth: { xs: "95%", lg: "80%", xl: "70%" },
            margin: "auto auto",
            width: "100%",
          }}
        >
          <Typography dangerouslySetInnerHTML={{ __html: data?.content }} />
        </Box>
      </Box>
    </>
  );
};

export default TermsAndConditions;
