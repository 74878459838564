import "./App.css";
import { Routes, Route } from "react-router-dom";
import Body from "./Layout/Body";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import AllBlogs from "./Pages/AllBlogs";
import BlogDetails from "./Pages/BlogDetails";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/allblogs" element={<AllBlogs />} />
        <Route path="/blogdetails" element={<BlogDetails />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
