import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import LEFT_LOOPER from "../Assets/Images/footerlooperleft.png";
import RIGHT_LOOPER from "../Assets/Images/footerlooperright.png";
import LOGO from "../Assets/Images/outputsvg.png";
import FACEBOOK from "../Assets/Images/facebookIcon.png";
import INSTAGRAM from "../Assets/Images/InstagramIcon.png";
import PINTREST from "../Assets/Images/pintrestIcon.png";
import PLAYSTORE from "../Assets/Images/playstore.png";
import APPSTORE from "../Assets/Images/appstore.png";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleMenuClick = (id) => {
    if (location.pathname === "/allblogs" || "/termsandconditions" || "/privacypolicy") {
      navigate("/");
    } else {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleJoinUsClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "linear-gradient(180deg, #0D0C14 100%, #3B3055 100%)",
          minHeight: "42vh",
          py: 2,
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            px: { xs: 2, lg: 25 },
          }}
        >
          <img
            src={LEFT_LOOPER}
            alt=""
            style={{ position: "absolute", left: "0px", top: "0px",width:"300px" }}
          />
          <img
            src={RIGHT_LOOPER}
            alt=""
            style={{ position: "absolute", right: "0px", top: "15px",width:"280px" }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "start",
              gap: { xs: "50px", md: "0px" },
              pt: 3,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <img src={LOGO} alt="" style={{ width: "100px" }}></img>
              <Typography
                sx={{
                  textAlign: "start",
                  color: "white",
                  fontSize: { xs: "14px", md: "14px" },
                  width: "250px",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. ard dummy text ever since the 1500s,
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "200px",
                  gap: "20px",
                }}
              >
                <img
                  src={FACEBOOK}
                  alt=""
                  style={{ width: "40px", cursor: "pointer" }}
                />
                <img
                  src={INSTAGRAM}
                  alt=""
                  style={{ width: "40px", cursor: "pointer" }}
                />
                <img
                  src={PINTREST}
                  alt=""
                  style={{ width: "40px", cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "25px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", color: "white" }}>
                    Menu
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => handleMenuClick("home")}
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Home
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => handleMenuClick("whyus")}
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Why us
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => handleMenuClick("pricing")}
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Pricing
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => handleMenuClick("ourpartner")}
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Our Partner
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => handleMenuClick("faq")}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      FAQs
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "25px",
                  ml: -2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", color: "white" }}>
                    Company
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={handleJoinUsClick}
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Join Us
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => handleMenuClick("support")}
                      smooth={true}
                      duration={800}
                      offset={-100}
                    >
                      Support
                    </Link>
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => navigate("/allblogs")}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      All blogs
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => navigate("/privacypolicy")}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "200",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Link
                      onClick={() => navigate("/termsandconditions")}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      Terms And Conditions 
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <Typography style={{ color: "white", fontWeight: "500" }}>
                Download App
              </Typography>
              <img
                src={PLAYSTORE}
                alt=""
                style={{ width: "150px", cursor: "pointer" }}
              />
              <img
                src={APPSTORE}
                alt=""
                style={{ width: "150px", cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Modal Component */}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ position: "relative", padding: "24px" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Join Us
          </Typography>
          <TextField fullWidth label="Name" variant="outlined" sx={{ mb: 2 }} />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Footer;
