import axios from "axios";
const AxiosInstance = axios.create({
  baseURL: "https://devapi.transformationalrunners.com",
});
AxiosInstance.interceptors.request.use(
  function (config) {
    const transfomationalRunner = JSON.parse(localStorage.getItem("TRANSFOMATIONAL_RUNNER_WEB_APPLICATION"));
    config.headers.token = transfomationalRunner?.token;
    config.headers.apikey = transfomationalRunner?.apikey;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  // function (error) {
  //   if (error.response) {
  //     if (error.response.status === 401) {
  //       localStorage.removeItem("transfomationalRunner");
  //       window.location.pathname = "/";
  //     } else {
  //       console.error("Response Error:", error.response);
  //     }
  //   } else if (error.request) {
  //     console.error("Request Error:", error.request);
  //   } else {
  //     console.error("Error:", error.message);
  //   }
  //   return Promise.reject(error);
  // }
);

export default AxiosInstance;
