import React from 'react'
import Home from '../Pages/Home'
import WhyChooseUs from '../Pages/WhyChooseUs'
import Pricing from '../Pages/Pricing'
import BecomeaTransfometional from '../Pages/BecomeaTransfometional'
import Blogs from '../Pages/Blogs'
import FAQ from '../Pages/FAQ'
import Support from '../Pages/Support'



const Body = () => {
  return (
    <>
      <Home/>
      <WhyChooseUs/>
      <Pricing/>
      <BecomeaTransfometional/>
      <Blogs/>
      <FAQ/>
      <Support/>
    </>
  )
}

export default Body
