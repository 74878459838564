import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HOME_BACKGROUND from "../Assets/Images/HomeBackground.webp";
import GYM_DUMBLE from "../Assets/Images/gym.png";
import PLAY_STORE from "../Assets/Images/playstore.png";
import APP_STORE from "../Assets/Images/appstore.png";
import HOME_MAIN_MOBILE from "../Assets/Images/homemain.png";
import LADY from "../Assets/Images/lady.svg";
import LOOPER from "../Assets/Images/Looper-1.png";
import APPSTORE_ICON from "../Assets/Images/appstoreicon.png";
import PLAYSTORE_ICON from "../Assets/Images/playstoreicon.png";
import RatingStar from "../Component/Ratings";
import { TypeAnimation } from "react-type-animation";
import RenderModal from "../Component/RenderModal";
import CookiesPolicyPopup from "../Component/CookiesPolicyPopup ";
const Home = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showCookiesPopup, setShowCookiesPopup] = useState(false);

  useEffect(() => {
    const cookiesAccepted = document.cookie
      .split("; ")
      .find((row) => row.startsWith("cookiesAccepted="));
    const isVisited = document.cookie
      .split("; ")
      .find((row) => row.startsWith("isVisited="));

    if (!cookiesAccepted) {
      setShowCookiesPopup(true);
      setOpenModal(true);
    } else if (cookiesAccepted.split("=")[1] === "true" && !isVisited) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, []);

  const handleCookiesAccepted = () => {
    document.cookie = "cookiesAccepted=true; path=/; max-age=31536000";
    document.cookie = "isVisited=true; path=/; max-age=31536000";
    setShowCookiesPopup(false);
    setOpenModal(true);
  };

  const handleCookiesRejected = () => {
    document.cookie = "cookiesAccepted=false; path=/; max-age=31536000";
    document.cookie =
      "isVisited=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      setShowCookiesPopup(false);
    setOpenModal(true);
  };
  return (
    <>
      {/* {Home Section} */}
      <div
        id="home"
        style={{
          width: "100%",

          minHeight: "100vh",
          background: "#E9E0FF",
          display: "flex",
          gap: "0px",
          margin: "0",
          padding: "0",
          paddingTop: "80px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            clipPath: "polygon(50% 0%, 100% 0, 100% 79%, 0 100%, 0 0)",
            background:
              "linear-gradient(168.95deg, #0D0C14 0.74%, #191825 98.27%)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: `url(${HOME_BACKGROUND})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", lg: "40%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                p: { xs: 3, sm: 5, md: 5 },
                pt: { xs: 5, md: 10 },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", lg: "80%" },
                  p: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: { xs: "200px", md: "300px" },
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    p: 0.8,
                    borderRadius: "15px",
                    boxShadow: "2px 2px 12px 0px #FFFFFF85",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={GYM_DUMBLE} alt="" />
                  </Box>
                  <Typography
                    sx={{
                      color: "#3B3055",
                      fontSize: { xs: "12px", md: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    Transfomational Runners
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", md: "28px" },
                      color: "#EFE9FF",
                      fontWeight: "500",
                    }}
                  >
                    Take your running to the
                  </Typography>
                  <TypeAnimation
                    className="main_header"
                    sequence={[
                      "R",
                      300,
                      "Ru",
                      300,
                      "Run ",
                      300,
                      "Run w",
                      300,
                      "Run wi",
                      300,
                      "Run wit",
                      300,
                      "Run with ",
                      300,
                      "Run with u",
                      300,
                      "Run with us",
                      300,
                    ]}
                    wrapper="span"
                    speed={100}
                    repeat={Infinity}
                  />

                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "18px" },
                      color: "#FFFFFF",
                      fontWeight: "200",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco.
                  </Typography>

                  <Box sx={{ width: "100%", display: "flex", gap: "20px" }}>
                    <img
                      src={PLAY_STORE}
                      alt="playstore"
                      style={{ width: "130px", cursor: "pointer" }}
                    />
                    <img
                      src={APP_STORE}
                      alt="appstore"
                      style={{ width: "130px", cursor: "pointer" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: { xs: "100%", lg: "60%" }, height: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: { xs: "center", lg: "end" },
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={HOME_MAIN_MOBILE}
                    alt=""
                    id="zio"
                    style={{
                      width: "95%",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "500px",
            height: "500px",
            bottom: { lg: "-200px", xl: "-150px" },
            left: "0px",
            order: 1,
            display: { xs: "none", lg: "initial" },
          }}
        >
          <img src={LADY} alt="" style={{ width: "100%" }} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "800px",
            height: "800px",
            bottom: "-650px",
            right: "0px",
            order: 1,
            display: { xs: "none", lg: "initial" },
          }}
        >
          <img src={LOOPER} alt="" style={{ width: "100%" }} />
        </Box>
      </div>
      <Box
        sx={{
          width: "100%",
          background:
            "linear-gradient(180deg, rgba(177, 144, 255, 0.28) 14.09%, rgba(239, 233, 255, 0.196) 105.46%)",
          display: "flex",
          gap: "0px",
          margin: "0",
          padding: { xs: "20px", md: "50px" },
          paddingTop: "80px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "40%" },
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "23px", lg: "40px" }, fontWeight: "500" }}
            >
              <span style={{ color: "#B190FF" }}>Review</span> from our user
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolor.
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "400px" },
                border: "1px solid #6A569950",
                borderRadius: "32px",
                background:
                  "linear-gradient(180deg, rgba(239, 233, 255, 0.64) 0%, rgba(208, 188, 255, 0.64) 100%)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%", gap: "10px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={APPSTORE_ICON}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Box>
                    <Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                        >
                          <span style={{ color: "#7660AA" }}>10000+</span>{" "}
                          Ratings on App Store
                        </Typography>
                      </Box>
                      <Box>
                        <RatingStar rating={4.5} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" } }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "400px" },
                border: "1px solid #6A569950",
                borderRadius: "32px",
                background:
                  "linear-gradient(180deg, rgba(239, 233, 255, 0.64) 0%, rgba(208, 188, 255, 0.64) 100%)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%", gap: "10px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={PLAYSTORE_ICON}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Box>
                    <Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                        >
                          <span style={{ color: "#7660AA" }}>10000+</span>{" "}
                          Ratings on Play Store
                        </Typography>
                      </Box>
                      <Box>
                        <RatingStar rating={2.5} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" } }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <RenderModal open={openModal} onClose={() => setOpenModal(false)} />
      {showCookiesPopup && (
        <CookiesPolicyPopup
          onAccept={handleCookiesAccepted}
          onReject={handleCookiesRejected}
        />
      )}
    </>
  );
};

export default Home;
