import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-scroll";
import logo from "../Assets/Images/1024.webp";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [openModal, setOpenModal] = useState(false); // Modal state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };
  const handleLogoClick = () => {
    navigate("/"); // Navigate to the homepage
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
  };
  const handleModalToggle = () => {
    setOpenModal(!openModal);
    setOpenMenu(false);
  };

  const handleMenuClick = () => {
    if (location.pathname === "/allblogs" || "/termsandconditions" || "/privacypolicy") {
      navigate("/");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuItems = [
    { text: "Home", id: "home" },
    { text: "Why Us", id: "whyus" },
    { text: "Pricing", id: "pricing" },
    { text: "Our Partner", id: "ourpartner" },
    { text: "Blogs", id: "blog" },
    { text: "FAQs", id: "faq" },
    { text: "Support", id: "support" },
  ];

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email address is required!")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please Provide Valid Email"
        ),
      message: Yup.string().required("Please enter your message here"),
    }),
    onSubmit: async (values, { resetForm }) => {},
  });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#0D0C14",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: "999",
          transition: "all 0.3s ease-in-out",
          boxShadow: !scroll
            ? "0 0 0px rgba(0,0,0,0)"
            : "0 0 10px rgba(0,0,0,0.1)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 2, md: 2 },
            py: 0,
            background: "transparent",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "200px" },
              display: "flex",
              justifyContent: { xs: "start", lg: "center" },
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "80px", cursor: "pointer" }}
              onClick={handleLogoClick}
            />
          </Box>

          {isMobile ? (
            <>
              <Box
                sx={{
                  width: { xs: "120px", sm: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  sx={{
                    background: "transparent",
                    "&:hover": { background: "transparent" },
                  }}
                  onClick={handleMenuToggle}
                >
                  <MenuIcon
                    sx={{
                      fontSize: { xs: "28px", sm: "32px" },
                      color: "#C0A6FE",
                    }}
                  />
                </IconButton>
              </Box>
              <Drawer anchor="right" open={openMenu} onClose={handleMenuToggle}>
                <Box
                  sx={{
                    width: "100%",
                    p: 0,
                    background: "#0D0C14",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={logo} style={{ width: "150px" }} alt="" />
                </Box>
                <List
                  sx={{ width: { xs: "200px", sm: "300px" }, fontSize: "22px" }}
                >
                  {menuItems.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        ".css-10hburv-MuiTypography-root": { fontSize: "20px" },
                      }}
                      onClick={handleMenuToggle}
                    >
                      <Link
                        to={item.id}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={() => setOpenMenu(false)}
                      >
                        <ListItemText primary={`${item.text}`} />
                      </Link>
                    </ListItem>
                  ))}

                  <ListItem>
                    <Button
                      sx={{
                        height: "54px",
                        width: "119px",
                        borderRadius: "10px",
                        color: "black",
                        background:
                          "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                        "&:hover": {
                          background:
                            "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                          color: "black",
                        },
                      }}
                      onClick={handleModalToggle} // Open modal on click
                    >
                      Join US
                    </Button>
                  </ListItem>
                </List>
              </Drawer>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", md: "100%", lg: "900px" },
              }}
            >
              <ul
                className="navul"
                style={{
                  listStyle: "none",
                  display: "flex",
                  margin: 0,
                  padding: 0,
                }}
              >
                {menuItems.map((item, index) => (
                  <li
                    className="navli"
                    key={index}
                    style={{ margin: "0 10px" }}
                  >
                    <Link
                      to={item.id}
                      smooth={true}
                      duration={500}
                      offset={-100}
                      onClick={() => handleMenuClick(item.id)}
                    >
                      {item.text}
                    </Link>
                  </li>
                ))}
                <li>
                  <Button
                    onClick={handleModalToggle}
                    sx={{
                      height: "54px",
                      width: "119px",
                      borderRadius: "10px",
                      color: "black",
                      fontSize: "16px",
                      background:
                        "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                      "&:hover": {
                        background:
                          "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                        color: "black",
                      },
                    }}
                  >
                    Join US
                  </Button>
                </li>
              </ul>
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleModalToggle}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "auto",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 3,
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "28px" }}>
              Join Us
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenModal(false)}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{ fontSize: "28px" }} />
            </IconButton>
          </Box>
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              gap: "20px",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <TextField
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={"Email Address"}
              sx={{
                outline:
                  formik.touched.email && formik.errors.email
                    ? "1.5px solid red"
                    : "none",
                width: { xs: "100%", md: "500px" },
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 2px 8px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.email}
              </Typography>
            ) : null}
            <TextField
              name="message"
              value={formik.values.message}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={"Message"}
              sx={{
                outline:
                  formik.touched.message && formik.errors.message
                    ? "1.5px solid red"
                    : "none",
                width: { xs: "100%", md: "500px" },
                mt: "10px",
                "& .MuiOutlinedInput-notchedOutline ": {
                  outline: "none",
                  border: "none",
                },
                "& .MuiInputBase-root": {
                  border: "none",
                  background: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                },
                boxShadow: "0px 2px 8px 0px #00000040",
                borderRadius: "12px",
              }}
            />
            {formik.touched.message && formik.errors.message ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {formik.errors.message}
              </Typography>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  width: "100px",
                  marginTop: "20px",
                  background: "#3B3055",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "400",
                  borderRadius: "12px",
                  "&:hover": { background: "#3B3055" },
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
