import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const CookiesPolicyPopup = ({ onAccept, onReject }) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: '#3B3055',
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 20px',
                zIndex: 1000000,
                p:2,
                flexDirection:{xs:"column",md:"row"},
                gap:"20px"
            }}
        >
            <Typography sx={{ fontSize: {xs:"14px",md:"22px"} }}>
                We use cookies to ensure you get the best experience on our website.
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button
                    variant="contained"
                    color="success"
                    onClick={onAccept}
                    sx={{
                        backgroundColor: '#3B3055',
                        color:'white',
                        border:"1px solid white",
                        '&:hover': { backgroundColor: 'white', color:'#3B3055',
                            border:"white", border:"1px solid white"},
                        textTransform: 'none',
                        fontSize:"18px",
                    }}
                >
                    Accept
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={onReject} 
                    sx={{
                        fontSize:"18px",
                        backgroundColor: '#f44336',
                        '&:hover': { backgroundColor: '#e53935' },
                        textTransform: 'none',
                    }}
                >
                    Reject
                </Button>
            </Box>
        </Box>
    );
};

export default CookiesPolicyPopup;
